import { 
  Toolbar,
  Typography,
  Breadcrumbs,
  Link,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import { useState, useEffect } from 'react'
import { Outlet, useMatches, useLocation, useParams, useNavigate } from "react-router-dom"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import { QRCodeSVG } from 'qrcode.react'
import { getProjectExists, getHasOrdered } from '../models/ProjectManager'
import { getHomeUrl, getPagename, getUrl } from '../models/PathManager'
import LangSelector from './LangSelector'
import { useTranslation } from 'react-i18next'
// import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blueGrey[300]
    },
    background: {
      default: blueGrey[900]
    }
  }
})

function Crumbs(props) {
  const { t } = useTranslation()

  const items = [
    [t("HOME"), ""], 
    [t("EDITOR"), "editor"], 
    [t("PROJECT"), "project"], 
    [t("OPTIONS"), "options"], 
    [t("ORDER"), null]
  ]
  let path = props.matches[1].pathname
  let projectId = null
  if (props.matches[1].params && props.matches[1].params.projectId) {
    projectId = props.matches[1].params.projectId
  }
  path = getPagename(path, projectId)
  
  const breadcrumbs = []
  for (let item of items) {
    let clone = Object.assign({}, item)
    if (item[0] === "HOME") {
      clone[1] = getHomeUrl(props.matches[1].pathname)
    } else {
      clone[1] = getUrl(clone[1], props.matches[1].pathname, projectId)
    }
    breadcrumbs.push(clone)
    if (item[1] === path) {
      breadcrumbs[breadcrumbs.length - 1][1] = null
      break
    }
  }
  return (
    <Breadcrumbs 
      sx={{
        mt: 3,
        ml: 2
      }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      {breadcrumbs.map( breadcrumb => (
        (
          breadcrumb[1] ?
            <Link key={breadcrumb[0]} href={breadcrumb[1]}>{breadcrumb[0]}</Link>
            :
            <Typography key={breadcrumb[0]}>{breadcrumb[0]}</Typography>
        )
      ))}
    </Breadcrumbs>
  )
}

function Root(props) {

  const matches = useMatches()
  const location = useLocation()
  const { projectId } = useParams()
  const navigate = useNavigate()

  const FORMURL_SHORT = "https://forms.gle/jSkGDkUa1ocv4RrWA"
  const FORMURL_PREFIX = "https://docs.google.com/forms/d/e/1FAIpQLSco0HFr5DU9uK_ciUfZ73vb5H-ECmpkvs-OJaVwr1OKIcDd2w/viewform?usp=pp_url&entry.1960556003="

  const [thisurl, setThisUrl] = useState("")
  const [formurl, setFormUrl] = useState("")
  const [hasOrdered, setHasOrdered] = useState(null)
  const [projectExists, setProjectExists] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  
  const { t } = useTranslation()

  /*
  useEffect(() => {
    console.log(
      "projectId:" + projectId +
      ", projectExists:" + projectExists +
      ", hasOrdered:" + hasOrdered +
      ", location.pathname:" + location.pathname
    )
  }, [projectId, projectExists, hasOrdered, location.pathname])
  */

  useEffect(() => {
    async function doGetExists() {
      const exists = await getProjectExists(projectId)
      setProjectExists(exists)
    }
    if (projectId !== undefined) {
      doGetExists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  useEffect(() => {
    if (projectExists === false) {
      navigate(getHomeUrl(location.pathname))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectExists])

  useEffect(() => {
    async function doGetHas() {
      const _ho = await getHasOrdered(projectId)
      setHasOrdered(_ho)
      if (_ho) {
        navigate('/order/' + projectId)
      }
    }
    if (projectId !== undefined && projectExists === true) {
      doGetHas()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, projectExists])

  useEffect(() => {
    setThisUrl(window.location.protocol + "//" + window.location.host + location.pathname)
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === "/") {
      setFormUrl(FORMURL_SHORT)
    } else {
      setFormUrl(FORMURL_PREFIX + thisurl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisurl])

  function handleOpenAssembly() {
    window.open("/assembly/", "_blank")
  }

  function handleOpenFirmware() {
    window.open("/keymap/upload/", "_blank")
  }

  function handleOpenPolicy() {
    window.open("/policy/", "_blank")
  }

  function handleOpenContact() {
    window.open(formurl, "_blank")
  }

  function handleOpenMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleCloseMenu() {
    setAnchorEl(null)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toolbar>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ flexGrow: 1 }}>
          <Crumbs matches={matches} />
        </Typography>
        <Link key="about" href="/about" variant="body2" sx={{ mt: 4, mr: 3 }}>{t('ABOUT')}</Link>
        <IconButton 
          sx={{ mt: 4, mr: 3 }}
          onClick={handleOpenMenu}
          >
          <ErrorOutlineIcon />
        </IconButton>
        <LangSelector />
      </Toolbar>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleOpenAssembly}>{t('Assembly')}</MenuItem>
        <MenuItem onClick={handleOpenFirmware}>{t('Change Firmware')}</MenuItem>
        <MenuItem onClick={handleOpenPolicy}>{t('Policy')}</MenuItem>
        <MenuItem onClick={handleOpenContact}>{t('Contact Form')}</MenuItem>
      </Menu>

      {(projectExists === true && hasOrdered === false) || location.pathname === getHomeUrl(location.pathname)
        ? <Outlet />
        : ""
      }
    </ThemeProvider>
  )
}

export default Root
