import * as React from 'react'
import { useTranslation } from 'react-i18next'
// import i18next from 'i18next';
import { 
  Container,
  Box,
  Typography,
  Stack,
  Paper,
  Fab,
  Modal,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  AppBar
  } from '@mui/material'
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PauseIcon from '@mui/icons-material/Pause';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState, useEffect, useRef } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import AdjustIcon from '@mui/icons-material/Adjust'

const sampleImages = [
  { type: "image", src: "/about/DSC_0023_H.webp" },
  { type: "image", src: "/about/DSC_0024_H.webp" },
  { type: "image", src: "/about/DSC_0025_H.webp" },
  { type: "image", src: "/about/DSC_0026_H.webp" },
  { type: "image", src: "/about/DSC_0027_H.webp" },
  { type: "image", src: "/about/DSC_0028_H.webp" },
  { type: "image", src: "/about/DSC_0029_H.webp" },
  { type: "image", src: "/about/DSC_0030_H.webp" },
  { type: "image", src: "/about/DSC_0031_H.webp" },
  { type: "image", src: "/about/DSC_0032_H.webp" },
  { type: "image", src: "/about/DSC_0033_H.webp" },
  { type: "image", src: "/about/DSC_0034_H.webp" },
];

function Images(props) {

  const [slideIndex, setSlideIndex] = useState(0)
  // const slideTimer = useRef(null);
  // const [sliding, setSliding] = useState(true)
  /*
  function handleChange(event) {
    setSliding(false)
    setSlideIndex(event.target.value)
  }
  */
  function handleForward(event) {
    // setSliding(false)
    let idx = slideIndex + 1
    if (sampleImages.length <= idx) {
      idx = 0
    }
    setSlideIndex(idx)
  }

  function handleBack(event) {
    // setSliding(false)
    let idx = slideIndex - 1
    if (idx < 0) {
      idx = sampleImages.length - 1
    }
    setSlideIndex(idx)
  }

  /*
  useEffect(() => {
    if (sliding === true) {
      slideTimer.current = setInterval(() => {
        let idx = slideIndex + 1
        setSlideIndex(idx)
        if (props.src.length == idx) {
          setSlideIndex(0)
        }
      }, 2000)
    } else {
      clearInterval(slideTimer.current)
    }

    return () => clearInterval(slideTimer.current);
  }, [slideIndex, sliding, slideTimer, props.src]);
  */

  /*
    <Box 
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}>
      <Slider
        value={slideIndex}
        step={1}
        marks
        min={0}
        max={props.src.length - 1}
        valueLabelDisplay="auto"
        onChange={handleChange}
        sx={{
          width: "50%"
        }}/>
    </Box>
  */

  return (
    <Stack spacing={2}>
      <Box sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          position: "relative"
        }}>
        <Box onClick={props.open}>
          <img src={props.src[slideIndex].src} alt="key images" />
        </Box>
      </Box>
      <Fab 
        sx={{
          position: "absolute",
          top: "75%",
          right: "30%",
          zIndex: 'tooltip'
        }}
        onClick={handleForward}>
        <ArrowForwardIosIcon />
      </Fab>
      <Fab 
        sx={{
          position: "absolute",
          top: "75%",
          left: "30%",
          zIndex: 'tooltip'
        }}
        onClick={handleBack}>
        <ArrowBackIosNewIcon />
      </Fab>
    </Stack>
  );
}


function TheVideo(props) {

  const videoRef = useRef(null);
  const imgRef = useRef(null);
  const [playing, setPlaying] = useState(false)
  // const [show, setShow] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  /*
  function handleChange() {
    //let state = !playing
    //setPlaying(state)
  }
  */
  /*
  function handleMouseOver(event) {
    setShow(true)
    setPlaying(true)
  }
  */
  /*
  function handleMouseOut(event) {
    setShow(false)
    setPlaying(false)
  }
  */

  useEffect(() => {
    let newShowVideo = false
    if (props.thumbnail) {
      if (playing) {
        newShowVideo = true
      }
    } else {
      newShowVideo = true
    }
    setShowVideo(newShowVideo);
  }, [playing, props.thumbnail]);

  useEffect(() => {
    if (videoRef) {
      if (playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [playing, videoRef]);

  useEffect(() => {
    if (videoRef) {
      if (props.play) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [props.play, videoRef]);
  /*
  function Control() {
    if (playing) {
      if (show) {
        return (
          <Fab color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              zIndex: 'tooltip'
            }}
            onClick={handleChange}>
            <PauseIcon />
          </Fab>
        )
      }
    } else {
      return (
        <Fab color="primary"
          sx={{
            position: "absolute",
            top: "50%",
            zIndex: 'tooltip'
          }}
          onClick={handleChange}>
          <PlayArrowIcon />
        </Fab>
      )
    }
    return ""
  }
  */

  function getType() {
    if (props.src.split('.').pop() === "webm") {
      return "video/webm"
    } else {
      return "video/mp4"
    }
  }

  return (
    <Box sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        position: "relative"
      }}
      //onMouseOver={handleMouseOver}
      //onMouseLeave={handleMouseOut}
      >
      <video muted playsInline loop ref={videoRef} style={{ display: showVideo ? '' : 'none' }} >
        <source src={props.src} type={getType()} />
      </video>
      <img id="image" src={props.thumbnail} ref={imgRef} alt="" style={{ display: showVideo ? 'none' : '' }} alt="" />
    </Box>
  );
}


function Icons(props) {

  function getColor(target) {
    if (target === props.focus) {
      return "#0A0"
    }
    return "#666"
  }

  return (
    <>
      <AdjustIcon sx={{ color: getColor("anykind") }} />
      <AdjustIcon sx={{ color: getColor("designing") }} />
      <AdjustIcon sx={{ color: getColor("fulfillment") }} />
      <AdjustIcon sx={{ color: getColor("parts") }} />
      <AdjustIcon sx={{ color: getColor("soldering") }} />
      <AdjustIcon sx={{ color: getColor("firmware") }} />
      <AdjustIcon sx={{ color: getColor("using") }} />
    </>
  )
}


function About() {

  const { t } = useTranslation()
  // const [lang, setLang] = useState('ja')
  // const [yposi, setYPosi] = useState(0)
  // const [yposi2, setYPosi2] = useState(0)
  // const [scrollY, setScrollY] = useState(0)
  const [videoTop, setVideoTop] = useState(0)
  const [videoBottom, setVideoBottom] = useState(0)
  const [play, setPlay] = useState("stop")
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const [designingPlay, setDesigningPlay] = useState(false)
  const [generatingPlay, setGeneratingPlay] = useState(false)
  const [firmwarePlay, setFirmwarePlay] = useState(false)
  const [usingPlay, setUsingPlay] = useState(false)
  const [fulfillmentPlay, setFulfillmentPlay] = useState(false)
  const [solderingPlay, setSolderingPlay] = useState(false)
  const [focus, setFocus] = useState(null)

  const anykind_ref = useRef();
  const designing_ref = useRef();
  const generating_ref = useRef();
  const fulfillment_ref = useRef();
  const parts_ref = useRef();
  const soldering_ref = useRef();
  const firmware_ref = useRef();
  const using_ref = useRef();

  const [anykindRect, setAnykindRect] = useState(null);
  const [designingRect, setDesigningRect] = useState(null);
  const [generatingRect, setGeneratingRect] = useState(null);
  const [fulfillmentRect, setFulfillmentRect] = useState(null);
  const [partsRect, setPartsRect] = useState(null);
  const [solderingRect, setSolderingRect] = useState(null);
  const [firmwareRect, setFirmwareRect] = useState(null);
  const [usingRect, setUsingRect] = useState(null);

  const partsTableSelectedColor = blueGrey[600]
  const partsTableUnselectedColor = blueGrey[900]

  const [colorPico, setColorPico] = useState(partsTableUnselectedColor);
  const [colorKeyswitch, setColorKeyswitch] = useState(partsTableUnselectedColor);
  const [colorKeycap, setColorKeycap] = useState(partsTableUnselectedColor);

  const boundings = {}
  boundings['anykind'] = {bounding: anykindRect, element: anykind_ref}
  boundings['designing'] = {bounding: designingRect, element: designing_ref}
  boundings['generating'] = {bounding: generatingRect, element: generating_ref}
  boundings['fulfillment'] = {bounding: fulfillmentRect, element: fulfillment_ref}
  boundings['parts'] = {bounding: partsRect, element: parts_ref}
  boundings['soldering'] = {bounding: solderingRect, element: soldering_ref}
  boundings['firmware'] = {bounding: firmwareRect, element: firmware_ref}
  boundings['using'] = {bounding: usingRect, element: using_ref}

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blueGrey[300]
      },
      background: {
        default: blueGrey[900]
      }
    }
  })

  function getColor(target) {
    if (target === focus) {
      return blueGrey[800]
    }
    return blueGrey[900]
  }

  function getBorder(target) {
    if (target === focus) {
      return 1
    }
    return 0
  }
  /*
  // onTouchMove={handleScroll}

  function handleScroll(event) {
    setYPosi2(event.nativeEvent.touches[0].pageY - event.nativeEvent.touches[0].clientY)
  }

  // onWheel={handleWheel} 

  function handleWheel(event) {
    // console.log(event)
    // console.log('clientY', event.nativeEvent.clientY)
    // console.log('deltaY', event.nativeEvent.deltaY)
    // console.log('movementY', event.nativeEvent.movementY)
    // console.log('offsetY', event.nativeEvent.offsetY)
    // console.log('pageY', event.nativeEvent.pageY)
    // console.log('screenY', event.nativeEvent.screenY)
    // console.log('wheelDeltaY', event.nativeEvent.wheelDeltaY)
    // console.log('y', event.nativeEvent.y)
    // setYPosi(event.nativeEvent.pageY - event.nativeEvent.clientY)
  }
  */

  function handleScroll() {
    // setScrollY(window.scrollY)
    console.log(window.scrollY)
    for (var key in boundings) {
      /*
      bottom: 2196
      height: 700
      left: 440
      right: 1292
      top: 1496
      width: 852
      x: 440
      y: 1496
      */
      //console.log(bounding.bounding)
      if (boundings[key]['top']) {
        // console.log(bounding.bounding)
        if (boundings[key]['top'] < (window.scrollY + 100) && (window.scrollY + 100) < boundings[key]['bottom']) {
          setFocus(key)
          // console.log(bounding.element)
          if (boundings[key].element === designing_ref) {
            console.log("play designing")
            setDesigningPlay(true)
          } else {
            //console.log("stop designing")
            setDesigningPlay(false)
          }
          if (boundings[key].element === generating_ref) {
            console.log("play generating")
            setGeneratingPlay(true)
          } else {
            //console.log("stop generating")
            setGeneratingPlay(false)
          }
          if (boundings[key].element === fulfillment_ref) {
            console.log("play fulfillment")
            setFulfillmentPlay(true)
          } else {
            //console.log("stop fulfillment")
            setFulfillmentPlay(false)
          }
          // console.log(bounding.element)
          if (boundings[key].element === soldering_ref) {
            console.log("play soldering")
            setSolderingPlay(true)
          } else {
            //console.log("stop designing")
            setSolderingPlay(false)
          }
          if (boundings[key].element === firmware_ref) {
            console.log("play firmware")
            setFirmwarePlay(true)
          } else {
            //console.log("stop firmware")
            setFirmwarePlay(false)
          }
          if (boundings[key].element === using_ref) {
            console.log("play using")
            setUsingPlay(true)
          } else {
            //console.log("stop using")
            setUsingPlay(false)
          }
          break
        }
      }
    }
  }

  const modalStyle = {
    position:'absolute',
    // top: '0%',
    // left: '0%',
    transform: 'translate(0%, 0%)',
    overflow:'auto',
    display:'block',
    margin: 'auto',
    border: '2px solid red',
  }

  const partsPicoText = `
  Raspberry Pi Pico
  `
  const partsSwitchText = `
  Keyswitchs
  `
  const partsCapText = `
  Keycaps
  `

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (anykind_ref.current) {
      let bounding = anykind_ref.current.getBoundingClientRect()
      setAnykindRect(bounding)
      console.log(bounding.top + window.scrollY, bounding.bottom + window.scrollY)
      boundings['anykind']['top'] = bounding.top + window.scrollY
      boundings['anykind']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anykind_ref]);

  useEffect(() => {
    if (designing_ref.current) {
      let bounding = designing_ref.current.getBoundingClientRect()
      setDesigningRect(bounding)
      console.log(bounding.to + window.scrollY, bounding.bottom + window.scrollY)
      boundings['designing']['top'] = bounding.top + window.scrollY
      boundings['designing']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designing_ref]);

  useEffect(() => {
    if (generating_ref.current) {
      let bounding = generating_ref.current.getBoundingClientRect()
      setGeneratingRect(bounding)
      console.log(bounding.to + window.scrollY, bounding.bottom + window.scrollY)
      boundings['generating']['top'] = bounding.top + window.scrollY
      boundings['generating']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generating_ref]);

  useEffect(() => {
    if (firmwareRect) {
      setVideoTop(firmwareRect.top)
      setVideoBottom(firmwareRect.bottom)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmwareRect]);

  useEffect(() => {
    if (fulfillment_ref.current) {
      let bounding = fulfillment_ref.current.getBoundingClientRect()
      setFulfillmentRect(bounding)
      console.log(bounding.top + window.scrollY, bounding.bottom + window.scrollY)
      boundings['fulfillment']['top'] = bounding.top + window.scrollY
      boundings['fulfillment']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillment_ref]);

  useEffect(() => {
    if (parts_ref.current) {
      let bounding = parts_ref.current.getBoundingClientRect()
      setPartsRect(bounding)
      console.log(bounding.top + window.scrollY, bounding.bottom + window.scrollY)
      boundings['parts']['top'] = bounding.top + window.scrollY
      boundings['parts']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parts_ref]);

  useEffect(() => {
    if (soldering_ref.current) {
      let bounding = soldering_ref.current.getBoundingClientRect()
      setSolderingRect(bounding)
      console.log(bounding.top + window.scrollY, bounding.bottom + window.scrollY)
      boundings['soldering']['top'] = bounding.top + window.scrollY
      boundings['soldering']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldering_ref]);

  useEffect(() => {
    if (firmware_ref.current) {
      let bounding = firmware_ref.current.getBoundingClientRect()
      setFirmwareRect(bounding)
      console.log(bounding.top + window.scrollY, bounding.bottom + window.scrollY)
      boundings['firmware']['top'] = bounding.top + window.scrollY
      boundings['firmware']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmware_ref]);

  useEffect(() => {
    if (using_ref.current) {
      let bounding = using_ref.current.getBoundingClientRect()
      setUsingRect(bounding)
      console.log(bounding.top + window.scrollY, bounding.bottom + window.scrollY)
      boundings['using']['top'] = bounding.top + window.scrollY
      boundings['using']['bottom'] = bounding.bottom + window.scrollY
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [using_ref]);

  function onMouseoverPico(event) {
    setColorPico(partsTableSelectedColor)
  }

  function onMouseleavePico(event) {
    setColorPico(partsTableUnselectedColor)
  }
  
  function onMouseoverKeyswitch(event) {
    setColorKeyswitch(partsTableSelectedColor)
  }

  function onMouseleaveKeyswitch(event) {
    setColorKeyswitch(partsTableUnselectedColor)
  }

  function onMouseoverKeycap(event) {
    setColorKeycap(partsTableSelectedColor)
  }

  function onMouseleaveKeycap(event) {
    setColorKeycap(partsTableUnselectedColor)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ top:'auto', bottom:0, bgcolor: blueGrey[900] }}>
        <Toolbar sx={{ justifyContent: "center", alignItems: 'center' }}>
          <Box>
            <Icons focus={focus} playing={play} />
          </Box>
        </Toolbar>
      </AppBar>
      <Modal
        open={modalOpen}
        onClick={handleModalClose}
        sx={modalStyle}>
        <img id="image" src="/about/large/DSC_0023.JPG" alt="" />
      </Modal>

      <Box sx={{bgcolor: blueGrey[900]}}>
        <Container maxWidth="md">
          <Stack spacing={2} >
            <Box 
              ref={anykind_ref}
                sx={{
                height: '820px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("anykind"),
                border: getBorder("anykind"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <Images src={sampleImages} open={handleModalOpen} />
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "89%",
                  position: "absolute"
                }}>
                {t("any kind")}
              </Typography>
            </Box>

            <Box 
              ref={designing_ref}
              sx={{
                height: '700px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("designing"),
                border: getBorder("designing"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <TheVideo src="/about/output-screen15.webm" autorun={false} play={designingPlay} />
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "85%",
                  position: "absolute"
                }}
                >
                {t("designing")}
              </Typography>
            </Box>

            <Box 
              ref={generating_ref}
              sx={{
                height: '700px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("generating"),
                border: getBorder("generating"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <TheVideo src="/about/generation.webm" autorun={false} play={generatingPlay} />
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "85%",
                  position: "absolute"
                }}>
                {t("generating")}
              </Typography>
            </Box>

            <Box 
              ref={fulfillment_ref}
              sx={{
                height: '720px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("fulfillment"),
                border: getBorder("fulfillment"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <TheVideo src="/about/order.webm" autorun={true} play={fulfillmentPlay} />
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "88%",
                  position: "absolute"
                }}
                >
                {t("ordering")}
              </Typography>
            </Box>

            <Box 
              ref={parts_ref}
              sx={{
                height: '800px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("parts"),
                border: getBorder("parts"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <Stack spacing={5}>
                <Stack direction="row" spacing={5}>
                  <Box 
                    onMouseOver={onMouseoverPico}
                    onMouseLeave={onMouseleavePico}
                    sx={{
                    height: '200px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                    }}>
                    <Tooltip title={partsPicoText} placement="top" arrow>
                      <img src="/about/parts-cap.webp" style={{width:'200px', height:'auto'}} alt="cap" />
                    </Tooltip>
                  </Box>
                  <Box 
                    onMouseOver={onMouseoverKeyswitch}
                    onMouseLeave={onMouseleaveKeyswitch}
                    sx={{
                      height: '200px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex'
                    }}>
                    <Tooltip title={partsSwitchText} placement="top" arrow>
                      <img src="/about/parts-switch.webp" style={{width:'200px', height:'auto'}} alt="switch" />
                    </Tooltip>
                  </Box>
                  <Box 
                    onMouseOver={onMouseoverKeycap}
                    onMouseLeave={onMouseleaveKeycap}
                    sx={{
                      height: '200px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}>
                    <Tooltip title={partsCapText} placement="top" arrow>
                      <img src="/about/parts-pico.webp" style={{width:'200px', height:'auto'}} alt="pico" />
                    </Tooltip>
                  </Box>
                </Stack>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>製品名</TableCell>
                        <TableCell align="right">必要数</TableCell>
                        <TableCell align="right">おおよその価格（円）</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          backgroundColor: colorPico
                        }}>
                        <TableCell>Raspberry Pi Pico</TableCell>
                        <TableCell align="right">1</TableCell>
                        <TableCell align="right">1,000円</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          backgroundColor: colorKeyswitch
                        }}>
                        <TableCell>Cherry MX互換キースイッチ（足ありのもの）</TableCell>
                        <TableCell align="right">キーの数</TableCell>
                        <TableCell align="right">100円 / 1個</TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          backgroundColor: colorKeycap
                        }}>
                        <TableCell>Cherry MX互換キーキャップ</TableCell>
                        <TableCell align="right">キーの数</TableCell>
                        <TableCell align="right">60円 /1個</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "87%",
                  position: "absolute"
                }}
                >
                {t("buying parts")}
              </Typography>
            </Box>

            <Box 
              ref={soldering_ref}
              sx={{
                height: '720px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("soldering"),
                border: getBorder("soldering"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <img src="/about/soldering.webp" alt="soldering" />
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "88%",
                  position: "absolute"
                }}
                >
                {t("soldering")}
              </Typography>
            </Box>
            
            <Box 
              ref={firmware_ref}
              sx={{
                height: '700px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("firmware"),
                border: getBorder("firmware"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <TheVideo src="/about/firmware.webm" autorun={true} play={firmwarePlay} />
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "85%",
                  position: "absolute"
                }}
                >
                {t("writing firmware")}
              </Typography>
            </Box>

            <Box
              ref={using_ref}
              sx={{
                height: '700px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative",
                backgroundColor: getColor("using"),
                border: getBorder("using"),
                borderColor: 'primary.main',
                borderRadius: '10px'
              }}>
              <TheVideo src="/about/typing.webm" autorun={true} play={usingPlay} />
              <Typography 
                variant="h4"
                component="h2"
                sx={{
                  top: "85%",
                  position: "absolute"
                }}
                >
                {t("using")}
              </Typography>
            </Box>

            <Box
              sx={{
                height: '700px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: "relative"
              }}>
              <Typography 
                variant="body1"
                sx={{
                  top: "80%",
                  position: "absolute"
                }}
                >
                {t("thank you for watching")}
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default About;
