import { 
  Toolbar,
  Link
} from '@mui/material'
import * as React from 'react'
import { Outlet } from "react-router-dom"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import LangSelector from './LangSelector'
import { useTranslation } from 'react-i18next'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blueGrey[300]
    },
    background: {
      default: blueGrey[900]
    }
  }
})

function Head() {

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toolbar>
        <Link key="home" href="/" sx={{ mt: 4, ml: 2, flexGrow: 1 }}>{t('HOME')}</Link>
        <LangSelector />
      </Toolbar>
      <Outlet />
    </ThemeProvider>
  )
}

export default Head
